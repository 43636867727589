import { useCallback } from 'react';

import { useI18n } from '@change/core/react/i18n';

import type { PetitionContext } from '../types';

export function useTrendingInMovement(): ({ objective, slug }: { objective: string; slug: string }) => PetitionContext {
	const { translate } = useI18n();

	return useCallback(
		({ slug, objective }) => {
			return {
				actionText: translate('fe.components.discover_feed.see_more'),
				href: `/m/${encodeURIComponent(slug)}?source_location=discover_feed`,
				label: translate('fe.components.discover_feed.movement.trending_in', {
					movement_name: `<strong>${objective}</strong>`,
				}),
				fullHeaderLink: true,
				contextType: 'trending_in_movement',
			};
		},
		[translate],
	);
}
