import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { ClientRender } from '@change/core/react/ssr/render';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Flex } from '@change/design-system/layout';
import { Heading } from '@change/design-system/typography';

import { PetitionList } from './components/PetitionList';

export function DiscoverFeed(): JSX.Element | null {
	return (
		<Flex
			as="section"
			sx={{
				backgroundColor: ['neutral-grey50', 'initial'],
				flexDirection: 'column',
			}}
			mx={[-8, 0]}
			pb={32}
			data-qa="home-discover-feed"
		>
			<Heading as="h2" size="h3" px={[16, 0]} py={[16, 32]}>
				<Translate value="fe.pages.home.discover_feed.header" />
			</Heading>
			<ClientRender placeholder={<Loader size="m" mx="auto" my={32} />}>
				<PetitionList />
			</ClientRender>
		</Flex>
	);
}
