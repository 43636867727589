import { useCallback } from 'react';

import { useTracking } from '@change/core/react/tracking';

import { trackLoadMoreClick } from '../../api/track';

export function useTrackLoadMoreClick(): () => void {
	const track = useTracking();

	return useCallback(() => {
		void trackLoadMoreClick(track);
	}, [track]);
}
