import { useState } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Box } from '@change/design-system/layout';

import type { FeaturedVictoryPetition } from 'src/app/pages/homepage/pageContext';

import { SimpleCarouselSlide } from './SimpleCarouselSlide';
import { SimpleCarouselThumbs } from './SimpleCarouselThumbs';
import { VictoryRibbon } from './VictoryRibbon';

type Props = {
	featuredVictories: readonly FeaturedVictoryPetition[];
};

export function SimpleCarousel({ featuredVictories }: Props): JSX.Element | null {
	const [currentCarouselIndex, setCurrentCarouselIndex] = useState(0);

	if (!featuredVictories.length) return null;

	return (
		<>
			<Box sx={{ position: 'relative' }}>
				<Box sx={{ position: 'absolute', zIndex: 1000, left: -9, top: -8 }}>
					<VictoryRibbon p={4}>
						<Translate value="fe.pages.home.victory" />
					</VictoryRibbon>
				</Box>
			</Box>
			<Box sx={{ position: 'relative' }} data-qa="victories-carousel">
				<SimpleCarouselSlide
					featuredVictories={featuredVictories}
					currentIndex={currentCarouselIndex}
					onCardSelect={setCurrentCarouselIndex}
				/>
				<SimpleCarouselThumbs
					featuredVictories={featuredVictories}
					currentIndex={currentCarouselIndex}
					onCardSelect={setCurrentCarouselIndex}
				/>
			</Box>
		</>
	);
}
