import { useCallback, useMemo } from 'react';

import { useInfiniteQuery } from '@tanstack/react-query';
import flatten from 'lodash/flatten';
import uniqBy from 'lodash/uniqBy';

import { useUtilityContext } from '@change/core/react/utilityContext';

import { getDiscoverFeed } from '../api/getDiscoverFeed';
import type { CardCount } from '../api/track';
import type { FeedItem } from '../api/types';

type UseDiscoverFeed = {
	items: readonly FeedItem[];
	fetchMore: () => Promise<void>;
	hasNextPage: boolean;
	isLoading: boolean;
	isFetchingNextPage: boolean;
	isError: boolean;
	cardCount: CardCount;
};

// eslint-disable-next-line max-lines-per-function
export function useDiscoverFeed(): UseDiscoverFeed {
	const utilityContext = useUtilityContext();

	const { isLoading, isFetchingNextPage, data, isError, fetchNextPage, hasNextPage } = useInfiniteQuery({
		queryKey: ['discover-feed-homepage'],
		queryFn: async ({ pageParam }) => {
			return getDiscoverFeed({ after: pageParam, utilityContext });
		},
		initialPageParam: '',
		getNextPageParam: (previousResult) => {
			return previousResult.pageInfo.endCursor || null;
		},
	});

	const fetchMore = useCallback(async () => {
		if (!isLoading) {
			await fetchNextPage();
		}
	}, [isLoading, fetchNextPage]);

	const items = useMemo(() => {
		return uniqBy(flatten(data?.pages?.map((page) => page.petitions)) || [], ({ node: { id } }) => id);
	}, [data?.pages]);

	return {
		items,
		fetchMore,
		isLoading,
		isFetchingNextPage,
		hasNextPage,
		isError,
		cardCount: {
			promoted: items.filter((i) => i.__typename === 'PromotedPetitionConnection').length,
			trending: items.filter((i) => i.__typename === 'FeaturedPetitionConnection').length,
			recommended: items.filter((i) => i.__typename === 'RecommendedPetitionConnection').length,
		},
	};
}
