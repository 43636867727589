import type { JSX } from 'react';

import type { PrefetchedUserDataProps } from '@change/core/react/prefetch';
import { withPrefetchedData } from '@change/core/react/prefetch';

import { CountryBannerContainer } from 'src/app/shared/components/banners/country';
import type { PrefetchedStarterBannerData } from 'src/app/shared/components/banners/starterDashPrompt';
import {
	prefetchStarterDashboardPromptingBanner,
	StarterDashPromptingBanner,
	useStarterDashboardPromptingBanner,
} from 'src/app/shared/components/banners/starterDashPrompt';
import { prefetchCountryBanner, useCountryBanner } from 'src/app/shared/hooks/banners/country';
import { isLoaded, isLoading } from 'src/app/shared/utils/async';

import { HomepageNotice, prefetchHomepageNotice, useHomepageNotice } from './HomepageNotice';

export const Banner = withPrefetchedData(
	({ prefetchedUserData }: PrefetchedUserDataProps<PrefetchedStarterBannerData>): JSX.Element | null => {
		const countryBanner = useCountryBanner('home');
		const starterDashBanner = useStarterDashboardPromptingBanner(prefetchedUserData);
		const homepageNotice = useHomepageNotice();

		if (isLoading(starterDashBanner)) return null;

		if (isLoaded(starterDashBanner) && starterDashBanner.enabled) {
			return (
				<StarterDashPromptingBanner
					location="home"
					bannerContext={{
						petitionId: starterDashBanner.petitionId,
						petitionSlug: starterDashBanner.petitionSlug,
						onClose: starterDashBanner.onClose,
					}}
				/>
			);
		}

		if (homepageNotice) {
			return <HomepageNotice homepageNotice={homepageNotice} />;
		}

		if (countryBanner) {
			return <CountryBannerContainer context="home" countryBanner={countryBanner} />;
		}

		return null;
	},
	{
		prefetchName: __MODULE_HASH__,
		prefetchDependencies: [StarterDashPromptingBanner, CountryBannerContainer, HomepageNotice],
		prefetchData: async (context) => {
			await Promise.all([prefetchCountryBanner(context), prefetchHomepageNotice(context)]);
		},
		prefetchUserData: async (context) => {
			const starterDashBanner = await prefetchStarterDashboardPromptingBanner(context);
			return { ...starterDashBanner };
		},
	},
);
