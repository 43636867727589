import type { JSX } from 'react';

import { Flex } from '@change/design-system/layout';
import { Heading, Text } from '@change/design-system/typography';

import { useSupportAwarenessEnabled } from './hooks/supportAwarenessEnabled';

export function SupportAwareness(): JSX.Element | null {
	const { enabled, title, content } = useSupportAwarenessEnabled();

	if (!enabled) return null;

	return (
		<Flex
			sx={{ flexDirection: 'column', alignItems: 'center', maxWidth: 550, textAlign: 'center' }}
			mt={16}
			data-testid="support-awareness"
		>
			<Heading as="h2" size="h4" my={8}>
				{title}
			</Heading>
			<Text as="div">{content}</Text>
		</Flex>
	);
}
