import { useCallback, useRef } from 'react';
import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Button } from '@change/design-system/components/actions';
import { Loader } from '@change/design-system/components/progressiveDisclosure';
import { Box } from '@change/design-system/layout';

import { useTrackCardClick } from '../hooks/tracking/useTrackCardClick';
import { useTrackLoadMoreClick } from '../hooks/tracking/useTrackLoadMoreClick';
import { useDiscoverFeed } from '../hooks/useDiscoverFeed';

import { PetitionCard } from './PetitionCard';

export function PetitionList(): JSX.Element | null {
	const { items, isLoading, isError, hasNextPage, fetchMore, isFetchingNextPage, cardCount } = useDiscoverFeed();
	const trackClick = useTrackCardClick();
	const trackLoadMoreClick = useTrackLoadMoreClick();

	const handleLoadMoreClick = useCallback(() => {
		void fetchMore();
		trackLoadMoreClick();
	}, [fetchMore, trackLoadMoreClick]);

	/**
	 * NOTE: We need to keep a reference to the last list element
	 * so that we have an element to return focus to after
	 * the "load more" button is interacted with. This allows
	 * keyboard users access to the newly inserted items
	 * without having to go backwards in the list
	 */
	const lastItemRef = useRef<HTMLLIElement>(null);
	const handleLoadMoreKeyDown = useCallback(
		(e: React.KeyboardEvent<EventTarget>) => {
			if (lastItemRef.current && (e.key === 'Enter' || e.key === ' ')) {
				e.preventDefault();
				lastItemRef.current.focus();
				lastItemRef.current.blur();
				void fetchMore();
				trackLoadMoreClick();
			}
		},
		[fetchMore, trackLoadMoreClick],
	);

	if (isError) return null;

	if (isLoading) return <Loader size="m" mx="auto" my={32} />;

	return (
		<>
			{/* using role due to https://www.scottohara.me/blog/2019/01/12/lists-and-safari.html */}
			<Box
				as="ul"
				role="list"
				sx={{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'& > li:not(:first-of-type)': {
						mt: 32,
					},
				}}
			>
				{items.map((item, index) => (
					<Box
						as="li"
						key={item.node.id}
						ref={index === items.length - 1 ? lastItemRef : undefined}
						tabIndex={-1}
						data-qa="petition-list-item"
					>
						<PetitionCard
							item={item}
							onClick={(trackingData) =>
								trackClick({
									item,
									position: index + 1,
									cardCount,
									...trackingData,
								})
							}
						/>
					</Box>
				))}
			</Box>
			{hasNextPage && (
				<Button
					type="button"
					variant="secondary"
					data-testid="load-more"
					sx={{
						flex: '1 0 auto',
					}}
					my={16}
					mx={[16, 0]}
					onClick={handleLoadMoreClick}
					onKeyDown={handleLoadMoreKeyDown}
					loading={isFetchingNextPage}
					loadingLabel="loading" // TODO translate label
				>
					<Translate value="fe.components.load_more.load_more" />
				</Button>
			)}
		</>
	);
}
