import type { JSX } from 'react';

import { Translate } from '@change/core/react/i18n';
import { Link as RouterLink } from '@change/core/react/router';
import { Link } from '@change/design-system/components/actions';
import { Html } from '@change/design-system/html';
import { Box } from '@change/design-system/layout';
import { linkResetStyles } from '@change/design-system/reset';
import { Heading, Text } from '@change/design-system/typography';

import type { FeaturedVictoryPetition } from 'src/app/pages/homepage/pageContext';
import { VerticalEllipsis } from 'src/app/shared/components/layout/ellipsis';

export function VictoryDescription({ victory }: { victory: FeaturedVictoryPetition }): JSX.Element {
	return (
		<Box px={16} pt={16}>
			<VerticalEllipsis
				height={190}
				ellipsis={
					<Text size="small" fontWeight="normal" color="primary-black">
						…{' '}
						<Link to={`/p/${victory.slug}`} ml={8}>
							<Translate value="fe.pages.home.victories.more" />
						</Link>
					</Text>
				}
			>
				<RouterLink
					to={`/p/${victory.slug}`}
					sx={{
						...linkResetStyles,
						// eslint-disable-next-line @typescript-eslint/naming-convention
						'&:hover': { color: 'primary-changeRed' },
					}}
				>
					<Heading size="h3" as="h3">
						{victory.displayTitle}
					</Heading>
				</RouterLink>
				{victory.description && (
					<Box mt={16}>
						<Html as="span" html={victory.description} />
					</Box>
				)}
			</VerticalEllipsis>
		</Box>
	);
}
