import type { UtilityContext } from '@change/core/react/utilityContext';

import { HomepageGetFeaturedTopicsByLocale } from './getFeaturedTopicsByLocale.graphql';
import {
	type HomepageGetFeaturedTopicsByLocaleQuery,
	type HomepageGetFeaturedTopicsByLocaleQueryVariables,
} from './getFeaturedTopicsByLocale.graphql';

export type FeaturedTopic = NonNullable<
	HomepageGetFeaturedTopicsByLocaleQuery['getFeaturedTopicsByLocale']['featuredTopics']
>[0];

export async function getFeaturedTopicsByLocale({
	gql: { fetch },
	i18n: { getLocale },
}: UtilityContext): Promise<
	NonNullable<HomepageGetFeaturedTopicsByLocaleQuery['getFeaturedTopicsByLocale']['featuredTopics']>
> {
	const { data } = await fetch<HomepageGetFeaturedTopicsByLocaleQuery, HomepageGetFeaturedTopicsByLocaleQueryVariables>(
		{
			query: HomepageGetFeaturedTopicsByLocale,
			variables: { locale: getLocale() },
			rejectOnError: true,
		},
	);
	const { success, status, featuredTopics } = data?.getFeaturedTopicsByLocale || {};
	if (!success) {
		throw new Error(`Unknown error calling getFeaturedTopicsByLocale query (status: ${status})`);
	}
	return featuredTopics || [];
}
