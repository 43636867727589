import type { PrefetchContext, PrefetchedDataProps } from '@change/core/react/prefetch';

import type { MergedState } from 'src/app/shared/hooks/state';
import { useMergedStates } from 'src/app/shared/hooks/state';

import { prefetchHomepagePageDataFromApi, useHomepagePageDataFromApi } from './pageData';
import { prefetchHomepagePageFcm, useAsyncHomepagePageFcm } from './pageFcm';

export type HomepagePagePrefetchedContext = Readonly<{
	pageData: Parameters<typeof useHomepagePageDataFromApi>[0];
}>;

export type HomepagePageCombinedState = MergedState<
	ReturnType<typeof useHomepagePageDataFromApi>,
	ReturnType<typeof useAsyncHomepagePageFcm>
>;

export function usePageContext(prefetchedData?: HomepagePagePrefetchedContext): HomepagePageCombinedState {
	return useMergedStates(useHomepagePageDataFromApi(prefetchedData?.pageData), useAsyncHomepagePageFcm());
}

export async function prefetchPageContext(context: PrefetchContext): Promise<HomepagePagePrefetchedContext> {
	const [pageData] = await Promise.all([prefetchHomepagePageDataFromApi(context), prefetchHomepagePageFcm(context)]);

	return { pageData };
}

export type HomepagePagePrefetchedDataProps = PrefetchedDataProps<HomepagePagePrefetchedContext>;
