import { createPrefetchableAsyncDataContext } from 'src/app/shared/contexts/asyncData';

import { getHomepagePageData } from './api';
import type { HomepagePageDataLoadError } from './types';

export const {
	Context: HomepagePageDataContext,
	Provider: HomepagePageDataContextProvider,
	useContext: useHomepagePageData,
	useAsyncData: useHomepagePageDataFromApi,
	prefetchAsyncData: prefetchHomepagePageDataFromApi,
} = createPrefetchableAsyncDataContext({
	name: 'HomepagePageDataContext',
	oldVersion: true,
	dataProperty: 'pageData',
	getData: getHomepagePageData,
	errorHandler: (_e: Error) => ({ error: 'homepage_page_data_unknown_error' satisfies HomepagePageDataLoadError }),
});
