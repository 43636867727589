import type { JSX } from 'react';

import { Link as RouterLink } from '@change/core/react/router';
import { Link } from '@change/design-system/components/actions';
import { Icon } from '@change/design-system/components/icon';
import { Html } from '@change/design-system/html';
import type { IconComponent } from '@change/design-system/icons';
import { Box, Flex } from '@change/design-system/layout';
import { linkResetStyles } from '@change/design-system/reset';
import { Text } from '@change/design-system/typography';

type HeaderProps = {
	actionText?: string;
	href: string;
	icon?: IconComponent;
	label: string;
	fullHeaderLink: boolean;
	onClick: () => void;
};

export function CardHeader({ actionText, href, fullHeaderLink, icon, label, onClick }: HeaderProps): JSX.Element {
	if (fullHeaderLink) {
		return (
			<RouterLink
				to={href}
				onClick={onClick}
				data-qa="context-link"
				sx={{
					...linkResetStyles,
					display: 'block',
					height: 45,
				}}
			>
				<Box
					variant="bordered"
					sx={{
						display: 'flex',
						alignItems: 'center',
						borderColor: 'neutral-grey100',
						borderRadius: 0,
						borderWidth: '0 0 1px 0',
						height: 45,
					}}
					px={16}
					py={8}
					data-qa="discover-card-header"
				>
					{icon && (
						<Flex sx={{ alignItems: 'center' }} mr={8}>
							<Icon icon={icon} size="s" />
						</Flex>
					)}
					<Html size="small" html={label} sx={{ flex: 1 }} />
					{actionText && (
						<Box ml="auto">
							<Text size="small" sx={{ textDecoration: 'underline', whiteSpace: 'nowrap' }}>
								{actionText}
							</Text>
						</Box>
					)}
				</Box>
			</RouterLink>
		);
	}
	return (
		<Box
			variant="bordered"
			sx={{
				display: 'flex',
				alignItems: 'center',
				borderRadius: 0,
				borderWidth: '0 0 1px 0',
				borderColor: 'neutral-grey100',
				height: 45,
			}}
			px={16}
			py={8}
			data-qa="discover-card-header"
		>
			{icon && (
				<Flex sx={{ alignItems: 'center' }} mr={8}>
					<Icon icon={icon} size="s" />
				</Flex>
			)}
			<Link to={href} onClick={onClick} data-qa="context-link">
				<Text size="small">
					<Html html={label} noContentStyles />
				</Text>
			</Link>
		</Box>
	);
}
