import { useMemo } from 'react';
import type { JSX } from 'react';

import { Helmet } from 'react-helmet-async';
import { useLocation, useParams } from 'react-router';

import { useI18n } from '@change/core/react/i18n';
import { useUtilityContext } from '@change/core/react/utilityContext';

import { HOMEPAGE_L10N_MAP } from 'config/routes-l10n';

import { OrganizationSchema } from './components/OrganizationSchema';

function resolveLocale(locale: string) {
	return locale.replace(
		/^([a-z]{2})(-[a-z]{2})?$/i,
		(_, g1: string, g2: string) => `/${g1.toLowerCase()}${g2 ? g2.toUpperCase() : ''}`,
	);
}

export function Head(): JSX.Element {
	const { resolveUrl } = useUtilityContext();
	const { translate } = useI18n();
	const { locale: localeParam } = useParams<{ locale?: string }>();
	const { pathname } = useLocation();

	// routes are case-insensitive, but we want the canonical url
	// to be case-sensitive, so we convert to properly formatted l10n
	const canonicalUrl = useMemo(() => {
		// react-router 6 removed support of regexs - there's an ongoing discussion to re-add them (https://github.com/remix-run/react-router/discussions/9844)
		const locale = localeParam || pathname.split('/')[1];
		if (locale) {
			const validL10n = locale ? HOMEPAGE_L10N_MAP[locale.toLowerCase()] : undefined;
			return validL10n ? resolveUrl(resolveLocale(locale)) : resolveUrl('/');
		}

		return resolveUrl('/');
	}, [localeParam, pathname, resolveUrl]);

	return (
		<>
			<Helmet>
				<link rel="canonical" href={canonicalUrl} />
				<title>{translate('fe.pages.home.title_tag')}</title>
				<meta property="title" content={translate('fe.pages.home.title_tag')} />
				<meta name="description" content={translate('fe.pages.home.description')} />
				<meta property="og:url" content={canonicalUrl} />
				<meta property="og:title" content={translate('fe.pages.home.title')} />
				<meta property="og:description" content={translate('fe.pages.home.og_description')} />
				<meta property="og:image" content="https://static.change.org/favicon/favicon-400x400.png" />
				<meta property="og:type" content="website" />
				<meta name="twitter:card" content="summary" />
				<meta property="twitter:image" content="https://static.change.org/favicon/favicon-400x400.png" />
				<meta property="twitter:image:alt" content={translate('fe.pages.home.title')} />
			</Helmet>
			<OrganizationSchema />
		</>
	);
}
