import isUndefined from 'lodash/fp/isUndefined';
import omitBy from 'lodash/fp/omitBy';

import type { TrackingFn } from '@change/core/react/tracking';

import { TYPENAME_TO_ITEM_TYPE } from '../shared/consts';

import type { FeedItem } from './types';

export type CardCount = {
	promoted: number;
	trending: number;
	recommended: number;
};

export type TrackCardClickEventData = {
	item: FeedItem;
	position: number;
	cardCount: CardCount;
	clickTarget: 'content' | 'context';
	clickTargetName:
		| 'promoted'
		| 'trending_in_tag'
		| 'trending_in_movement'
		| 'media_mention'
		| 'trending'
		| 'recommended';
	isNewUser: boolean;
	contextTagId?: string;
};
export async function trackCardClick(eventData: TrackCardClickEventData, track: TrackingFn): Promise<boolean> {
	const eventAttributes = omitBy(isUndefined, {
		card_position: eventData.position,
		// the card_type could be 'trending', 'promoted', or 'recommended'
		card_type: TYPENAME_TO_ITEM_TYPE[eventData.item.__typename],
		click_target: `components_discover_feed_${eventData.clickTarget}_${eventData.clickTargetName}`,
		context_tag_id: eventData.contextTagId || undefined,
		new_user: eventData.isNewUser,
		organization_id: eventData.item.node?.organization?.id,
		promoted: eventData.cardCount.promoted,
		trending: eventData.cardCount.trending,
		recommended: eventData.cardCount.recommended,
		view_context: 'home_index',
	});
	return track('feed_card_click', eventAttributes);
}

export async function trackLoadMoreClick(track: TrackingFn): Promise<boolean> {
	return track('feed_load_more_btn_click', { view_context: 'home_index' });
}
