import type { ComponentProps, JSX } from 'react';

import { Box } from '@change/design-system/layout';
import { Text } from '@change/design-system/typography';

export function VictoryRibbon({ children, ...rest }: ComponentProps<typeof Box>): JSX.Element {
	return (
		<Box
			sx={{
				borderRadius: '4px',
				backgroundColor: 'primary-changeRed',
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&::after': {
					content: '" "',
					position: 'absolute',
					left: 3,
					top: '100%',
					zIndex: 1,
					width: 0,
					height: 0,
					borderStyle: 'solid',
					borderWidth: '0 7px 6px 0',
					borderColor: 'transparent rgb(146, 38, 23) transparent transparent',
				},
			}}
			{...rest}
		>
			<Text
				as="p"
				color="primary-white"
				size="small"
				fontWeight="bold"
				sx={{
					textTransform: 'uppercase',
					letterSpacing: '1.5px',
				}}
				px={8}
			>
				{children}
			</Text>
		</Box>
	);
}
