import type { JSX } from 'react';

import { Box, Flex } from '@change/design-system/layout';
import { derivedStyle } from '@change/design-system/theme';

import type { FeaturedVictoryPetition } from 'src/app/pages/homepage/pageContext';

import { VictoryDescription } from './VictoryDescription';
import { VictoryMedia } from './VictoryMedia';
import { VictoryMetadata } from './VictoryMetadata';

export function FeaturedVictoryCard({ victory }: { victory: FeaturedVictoryPetition }): JSX.Element {
	return (
		<Box
			variant="bordered"
			sx={{
				width: '100%',
				backgroundColor: 'primary-white',
				borderColor: 'neutral-grey100',
				borderRadius: 'standard',
				boxShadow: derivedStyle(({ colors }) => `1px 1px ${colors['neutral-grey100']}`),
			}}
		>
			<Flex sx={{ height: 265 }}>
				<Box sx={{ flex: '0 0 50%' }}>
					<VictoryMedia victory={victory} />
				</Box>
				<Flex sx={{ flexDirection: 'column', flex: '1 0 50%', minWidth: 0 }}>
					<Box sx={{ flex: '1 0 auto' }}>
						<VictoryDescription victory={victory} />
					</Box>
					<Box mt="auto" sx={{ borderTop: derivedStyle(({ colors }) => `solid 1px ${colors['neutral-grey100']}`) }}>
						<VictoryMetadata victory={victory} />
					</Box>
				</Flex>
			</Flex>
		</Box>
	);
}
