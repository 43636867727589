import type { JSX } from 'react';

import { Link as RouterLink } from '@change/core/react/router';
import { linkResetStyles } from '@change/design-system/reset';
import { derivedStyle } from '@change/design-system/theme';
import { Text } from '@change/design-system/typography';

import type { FeaturedTopic } from 'src/app/pages/homepage/pageContext';

type Props = {
	featuredTopic: FeaturedTopic;
};

export function FeaturedTopicPill({ featuredTopic }: Props): JSX.Element {
	return (
		<RouterLink
			to={`/t/${featuredTopic.slug}?source_location=homepage`}
			data-qa="topic-pill"
			sx={{
				...linkResetStyles,
				border: derivedStyle(({ colors }) => `1px solid ${colors['neutral-grey600']}`),
				borderRadius: '10000rem',
				display: 'block',
				color: 'neutral-grey600',
				mt: 4,
				mb: 8,
				mx: 4,
				px: 8,
				py: 4,
				// eslint-disable-next-line @typescript-eslint/naming-convention
				'&:hover, &:focus': {
					color: 'primary-changeRed',
					borderColor: 'primary-changeRed',
				},
			}}
		>
			<Text
				as="div"
				ellipsis
				size="caption"
				color="inherit"
				sx={{
					// eslint-disable-next-line @typescript-eslint/naming-convention
					'&:first-letter': {
						textTransform: 'uppercase',
					},
				}}
			>
				{featuredTopic.name}
			</Text>
		</RouterLink>
	);
}
